
body {
	background-color: $secondary;
	font-family: $font-family-body; 
}

.MainContainer {
	border-top: solid $primary 40px;
}


.row, footer {
	padding: 30px 10px 30px 10px;
}

@media only screen and (min-width: 768px) {
	
	.row, footer {
		padding: 60px;
	}

}


/* ========= NAV BAR ========================== */

/* change the background color */
.navbar-custom {
    background-color: $gray-800;
    padding: 6px;
}

/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: $secondary;
	}

/* change the link color */
.navbar-custom .navbar-nav .nav-link {
    color: $secondary;
    border: 1px solid $secondary;
    padding: 10px 20px 10px 20px;
    margin: 5px;
    font-family: $headings-font-family;
}

/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
    color: $gray-400;
    background-color: $gray-800;
    border: 1px solid $gray-400;
}

.navbar-toggler {
	background-color: $white;
	border-radius: 15px;
    padding: 14px;
    color: $primary;
}

.brand-row {
	padding: 5px;
	font-family: $headings-font-family;
	color: $primary;
}

.navbar-text {
	border-bottom: 1px solid $primary;
	line-height: 1.4em;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 2em;	
	}

.Moreton {
	font-size: 1.2em;	
	margin-bottom: 6px;
}


@include media-breakpoint-up(md) { 
	
	.navbar-text {
		font-size: 3.4em;	
	}
}

 @include media-breakpoint-up(xl) {
	
	.navbar-text {
		font-size: 4em;	
	}

	.Moreton {
	font-size: 1.2em;
	margin-bottom: 10px;
	}

}



.navbar-text a:hover {
	text-decoration: none;
}



.green {
	background: $primary;
	color: $secondary;
	background-image: url("/assets/background-antiques.png");
	background-repeat: no-repeat;
	background-position: 10% center; 
}

.gray {
	background: $gray-800;
	color: $secondary;
	background-image: url("/assets/background-antiques.png");
	background-repeat: no-repeat;
	background-position: 10% center; 
}

.Title {
	padding-bottom: 20px;
	text-align: center;
}

h1, h2, h3 {
	color: $primary;
}

h2 {
	position: relative;
    overflow: hidden;
    text-align: center;
}

h2:before, h2:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 5%;
    height: 1px;
    content: '\a0';
    background-color: $primary;
}

h2:before {
    margin-left: -10%;
    text-align: right;
}

h2:after {
    margin-left: 5%;
    text-align: left;
}

.dealer {
	background-color: $gray-200;
	border-bottom: 1px solid $gray-400;
}

.cabinet-holder {
	margin-bottom: 20px;
}



/* ========= MAIL FORM ========================== */

		p.error, p.success {
			border: 1px solid;
			font-weight: bold;
			padding: 10px;
		}
		p.error {
			background: #ffc0c0;
			color: #900;
		}
		p.success {
			background: #ddf7c5;
			color: #4fa000;
		}


footer {
	background-color: $gray-200;
}



