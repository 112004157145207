// --------------------------------------------------
//
// Fonts
//
// --------------------------------------------------

// Font Stacks

$font-url-google		:	'https://fonts.googleapis.com/css?family=Merriweather|Montserrat';


$font-family-body		:		'Montserrat', sans-serif;
$headings-font-family  	:		'Merriweather', serif;
 
//==== Fonts

$base__font-size		:		16px;
$base__line-height		:		30px;

$h1-line-height		:		44px;
